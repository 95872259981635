import { Link } from 'gatsby';
import React from 'react';
import Artwork1 from '../images/artwork.svg';
import LogoBlack from '../images/logo-black.svg';
import LogoText from '../images/logo-text.svg';
import LogoYellow from '../images/logo-yellow.svg';
import GooglePlay from '../images/google-play.png';
import AppStore from '../images/app-store.png';
import { Helmet } from 'react-helmet';

function IndexPage() {
  return (
    <div className="relative w-screen min-h-screen flex flex-col">
      <Helmet>
        <title>Pinbuggy</title>
      </Helmet>
      <div className='absolute w-full h-[200px] bg-gradient-to-b from-amber-400 to-white -z-10'></div>
      <header className="flex justify-between items-center p-6 px-8">
        <h1 className='w-[125px] md:w-[200px]'>

          <LogoBlack width='auto' height='100%' />
        </h1>
        <ul className='font-semibold '>
          <li className='hover:underline'><Link to='/tracker'>Track my booking</Link></li>
        </ul>
      </header>
      <section className="flex flex-1 flex-col lg:flex-row w-full max-w-[1440px] gap-20 justify-center items-center mx-auto p-4 py-8">

        <div className='w-full shrink-0 max-w-[570px]'>

          <Artwork1 width='auto' height='100%' />
        </div>
        <div className='shrink-0 '>
          <LogoYellow />
          <h2 className='text-4xl font-bold'>Welcome to</h2>
          <h1 className='w-[300px] md:w-[550px]'>
            <LogoText width='auto' height='100%' />
          </h1>
          <p>
            Your on-demand courier for your <br />
            Padala and Pabili needs
          </p>
        </div>
      </section>
      <section className='flex flex-col items-center pb-5'>
        <h3 className='text-md font-bold mb-5'>Download the open beta now</h3>
        <div className="flex gap-5 px-5">
          <Link to='https://play.google.com/store/apps/details?id=com.wagiph.pinbuggyapp'>
            <img src={GooglePlay} />
          </Link>
          <Link className='cursor-not-allowed'>
            <img src={AppStore} />
          </Link>
        </div>
      </section>
    </div>
  );
}

export default IndexPage;
